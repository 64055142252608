import React from 'react';
import { Container } from './BannersSection.styled';
import Card2, { Card2Props } from '@/componentsNew/blocks/Cards/Card2/Card2';

export interface IBannerSection {
    banners: Card2Props[];
}
const BannersSection = ({ banners }: IBannerSection) => {
    return (
        <Container>
            {banners.map((banner, i) => (
                <Card2 key={i} {...banner} />
            ))}
        </Container>
    );
};

export default BannersSection;
