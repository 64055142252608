import React, { useState } from 'react';
import {
    Container,
    Left,
    Title,
    Text,
    LeadLinks,
    LeadLink,
    LeadLinkLogo,
    LeadLinkTitle,
    Angle,
    Right,
    SlideColumn,
    Slide
} from './Lead.styled';
import SearchProcedures from '@/components/blocks/SearchProcedures/SearchProcedures';
import Link from 'next/link';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '@/redux/rootReducer';
import useTranslation from '@/hooks/useTranslation';
import { sendMetrik } from '@/helpers';
import AngleIcon from '@/icons/cards/new/angle.svg';

import SimpleSlider from '@/componentsNew/blocks/SimpleSlider/SimpleSlider';
import { SwiperSlide } from 'swiper/react';
import useIsMatches from '@/hooks/useIsMatches';
import { Colors } from '@/style/colors';

const mapStateToProps = ({ preloader, pageTransition }: RootState) => ({
    preloader,
    pageTransition
});
const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export type LeadType = {
    title: string;
    description: string;
    companies: {
        name: string;
        href: string;
        image: string;
    }[];
    banners?: {
        title: string;
        text: string;
        image: string;
        href?: string;
        bgColor?: Colors;
        textColor?: Colors;
    }[];
};

const Lead: React.FC<PropsFromRedux & LeadType> = ({ title, description, companies }) => {
    const { t } = useTranslation('index');
    const [lead] = useState<LeadType>(() => t('lead', { returnObjects: true }));
    const isTablet = useIsMatches('xl');

    const slides = [];

    if (lead.banners) {
        for (let i = 0; i < lead.banners.length; i += 3) {
            slides.push(lead.banners.slice(i, i + 3));
        }
    }

    // @ts-ignore
    return (
        <Container className={'_prepare'}>
            <Left>
                {title && <Title>{title}</Title>}

                {description && <Text dangerouslySetInnerHTML={{ __html: description }} />}

                <SearchProcedures>
                    {companies && (
                        <LeadLinks>
                            {companies.map((company, index) => (
                                <Link href={company.href} passHref prefetch={false} key={index}>
                                    <LeadLink
                                        onClick={() => sendMetrik('search-click-section-teaser', 'search', { 'teaser-name': company.name })}
                                    >
                                        {lead.companies[index] && (
                                            <LeadLinkLogo
                                                src={lead.companies[index].image}
                                                alt={lead.companies[index].name}
                                                width={60}
                                                height={60}
                                                objectFit="contain"
                                            />
                                        )}
                                        <LeadLinkTitle>{lead.companies[index].name}</LeadLinkTitle>
                                    </LeadLink>
                                </Link>
                            ))}
                        </LeadLinks>
                    )}
                </SearchProcedures>

                <Angle>
                    <AngleIcon />
                </Angle>
            </Left>
            <Right>
                {isTablet && (
                    <SimpleSlider
                        swiperOptions={{
                            loop: true,
                            breakpoints: {
                                0: {
                                    slidesPerView: 1.05,
                                    spaceBetween: 12
                                },
                                768: {
                                    slidesPerView: 2,
                                    spaceBetween: 16
                                }
                            },
                            autoplay: {
                                delay: 5000,
                                disableOnInteraction: false
                            }
                        }}
                    >
                        {lead.banners?.map((banner, index) => (
                            <SwiperSlide key={index}>
                                <Slide {...banner} />
                            </SwiperSlide>
                        ))}
                    </SimpleSlider>
                )}
                {!isTablet && (
                    <SimpleSlider
                        swiperOptions={{
                            slidesPerView: 1,
                            spaceBetween: 16,
                            loop: true,
                            autoplay: {
                                delay: 5000,
                                disableOnInteraction: false
                            }
                        }}
                    >
                        {slides.map((slideBanners, index) => (
                            <SwiperSlide key={index}>
                                <SlideColumn>
                                    {slideBanners.map((banner, idx) => (
                                        <Slide key={idx} {...banner} />
                                    ))}
                                </SlideColumn>
                            </SwiperSlide>
                        ))}
                    </SimpleSlider>
                )}
            </Right>
        </Container>
    );
};

export default connector(Lead);
